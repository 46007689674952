/*
	jQuery.mmenu widescreen extension CSS
	
	To use on widescreens only, include it using a mediaquery:
	<link type="text/css" href="jquery.mmenu.widescreen.css" media="all and (min-width: 900px)"/>
*/

@import "../../css/_imp/import";


$mm_ws_menuWidth: 30% !default;

/*
html,
body
{
	overflow: auto !important;
}
*/
body
{
	position: relative;
}

#mm-blocker
{
	display: none !important;
}
.mm-slideout
{
	transform: none !important;
	width: 100 - $mm_ws_menuWidth !important;
	margin-left: $mm_ws_menuWidth !important;
}
.mm-page
{
	background: inherit;
	box-sizing: border-box;
}
.mm-menu.mm-widescreen
{
	border-right-width: 1px;
	border-right-style: solid;

	display: block !important;
	width: $mm_ws_menuWidth !important;
	min-width: none !important;
	max-width: none !important;

	top: 0 !important;
	right: auto !important;
	bottom: 0 !important;
	left: 0 !important;
	z-index: 100 !important;

	transform: none !important;

	&.mm-pageshadow:after
	{
		content: none;
		display: none;
	}
}